import {
  FormControl,
  Grid,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Action, Claim, Payment } from "./types";
import { Upload } from "./upload";
import AddIcon from "@material-ui/icons/Add";
import { DescriptionSelect } from "./inputs";

const HEADER_STYLE = { margin: "0.5em" };

export const PaymentEntry = React.memo(function PaymentEntry({
  payment,
  dispatch,
  index,
  showValidation,
  claimIndex,
}: {
  payment: Payment;
  dispatch: React.Dispatch<Action>;
  index: number;
  claimIndex: number;
  showValidation: boolean;
}): JSX.Element {
  return (
    <>
      <Grid item xs={3}></Grid>
      <Grid item xs={2} style={{ paddingTop: 15 }}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Betalingstype</InputLabel>
          <Select
            error={showValidation && !payment.type}
            value={payment.type}
            onChange={useCallback(
              (
                event: React.ChangeEvent<{
                  name?: string | undefined;
                  value: unknown;
                }>
              ) =>
                dispatch({
                  type: "updatePayment",
                  field: "type",
                  newValue: event.target.value,
                  index,
                  claimIndex,
                }),
              [claimIndex, dispatch, index]
            )}
            label="Betalingstype"
          >
            <MenuItem value="Rykkergebyr">Rykkergebyr</MenuItem>
            <MenuItem value="Indbetaling">Indbetaling</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <KeyboardDatePicker
          error={showValidation && !payment.date}
          format="dd/MM/yyyy"
          margin="normal"
          inputVariant="outlined"
          label="Dato"
          onChange={useCallback(
            (value: MaterialUiPickersDate) =>
              dispatch({
                type: "updatePayment",
                field: "date",
                newValue: value,
                index,
                claimIndex,
              }),
            [claimIndex, dispatch, index]
          )}
          value={payment.date}
          fullWidth
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          error={showValidation && !payment.amount}
          type="number"
          label="Beløb"
          variant="outlined"
          fullWidth
          value={payment.amount ?? ""}
          onChange={useCallback(
            (event: React.ChangeEvent<HTMLInputElement>) =>
              dispatch({
                type: "updatePayment",
                field: "amount",
                newValue: event.target.value,
                claimIndex,
                index,
              }),
            [claimIndex, dispatch, index]
          )}
        />
      </Grid>
      <Grid item xs={3}></Grid>
    </>
  );
});

export const ClaimsEntry = React.memo(function ClaimsEntry({
  claim,
  dispatch,
  index,
  showValidation,
}: {
  claim: Claim;
  dispatch: React.Dispatch<Action>;
  index: number;
  showValidation: boolean;
}): JSX.Element {
  const [errorMessage, setErrorMessage] = useState("");
  const onFileUploaded = useCallback(
    (file) => {
      setErrorMessage("");
      dispatch({
        type: "updateClaim",
        field: "appendices",
        newValue: [...claim.appendices, file],
        index,
      });
    },
    [claim.appendices, dispatch, index]
  );
  const handleFileUploadError = useCallback((error) => {
    setErrorMessage(
      "Der skete en fejl, prøv igen. Filen må ikke være over 3 mb"
    );
  }, []);
  return (
    <>
      <hr />
      <Grid container item spacing={1}>
        <Grid item xs={3}>
          <DescriptionSelect
            error={showValidation && !claim.description}
            value={{ value: claim.description, variant: claim.descriptionType }}
            onChange={useCallback(
              (value: { variant: "invoice" | "other"; value: string }) => {
                dispatch({
                  type: "updateClaim",
                  field: "description",
                  newValue: value.value,
                  index,
                });
                dispatch({
                  type: "updateClaim",
                  field: "descriptionType",
                  newValue: value.variant,
                  index,
                });
              },
              [dispatch, index]
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            type="number"
            label="Hovedstol"
            variant="outlined"
            fullWidth
            error={showValidation && !claim.principal}
            value={claim.principal ?? ""}
            onChange={useCallback(
              (event: React.ChangeEvent<HTMLInputElement>) =>
                dispatch({
                  type: "updateClaim",
                  field: "principal",
                  newValue: event.target.value,
                  index,
                }),
              [dispatch, index]
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <KeyboardDatePicker
            format="dd/MM/yyyy"
            margin="normal"
            inputVariant="outlined"
            label="Fakturadato"
            error={showValidation && !claim.invoiceDate}
            onChange={useCallback(
              (value: MaterialUiPickersDate) =>
                dispatch({
                  type: "updateClaim",
                  field: "invoiceDate",
                  newValue: value,
                  index,
                }),
              [dispatch, index]
            )}
            value={claim.invoiceDate}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          {claim.appendices.length ? (
            <ul>
              {claim.appendices.map((appendice) => (
                <li key={appendice.name}>{appendice.orginalName}</li>
              ))}
            </ul>
          ) : null}
          <Upload
            error={index === 0 && showValidation && !claim.appendices.length}
            multiple
            accept="application/pdf"
            onFileUploaded={onFileUploaded}
            onError={handleFileUploadError}
          />
          {errorMessage ? (
            <div style={{ color: "red" }}>{errorMessage}</div>
          ) : null}
        </Grid>
        {claim.payments.length ? (
          <>
            <Grid item xs={3}></Grid>
            <Grid item xs={2}>
              <div style={HEADER_STYLE}>Betalingstype</div>
            </Grid>
            <Grid item xs={2}>
              <div style={HEADER_STYLE}>Dato</div>
            </Grid>
            <Grid item xs={2}>
              <div style={HEADER_STYLE}>Beløb</div>
            </Grid>
            <Grid item xs={3} />
          </>
        ) : null}
        {claim.payments.map((payment, paymentIndex) => (
          <PaymentEntry
            showValidation={showValidation}
            key={paymentIndex}
            payment={payment}
            index={paymentIndex}
            claimIndex={index}
            dispatch={dispatch}
          />
        ))}
        <Grid item xs={3}></Grid>
        <Grid item xs={9}>
          <Button
            onClick={useCallback(
              () => dispatch({ type: "addPayment", claimIndex: index }),
              [dispatch, index]
            )}
            startIcon={<AddIcon />}
          >
            TIlføj rykkergebyr/indbetaling
          </Button>
        </Grid>
      </Grid>
    </>
  );
});

export const Claims = React.memo(function Claims({
  claims,
  dispatch,
  showValidation,
}: {
  claims: Claim[];
  showValidation: boolean;
  dispatch: React.Dispatch<Action>;
}): JSX.Element {
  return (
    <>
      <h4 style={{ margin: 0, marginBottom: 10 }}>
        <FormattedMessage defaultMessage="Opret hvert enkelt krav/faktura hver for sig" />
      </h4>

      <Grid container item spacing={1}>
        <Grid item xs={3}>
          <div style={HEADER_STYLE}>Beskrivelse (Fakturanr. etc)</div>
        </Grid>
        <Grid item xs={3}>
          <div style={HEADER_STYLE}>Hovedstol</div>
        </Grid>
        <Grid item xs={3}>
          <div style={HEADER_STYLE}>Fakturadato</div>
        </Grid>
        <Grid item xs={3}>
          <div style={HEADER_STYLE}>Bilag</div>
        </Grid>
      </Grid>
      {claims.map((claim, index) => (
        <ClaimsEntry
          showValidation={showValidation}
          key={index}
          claim={claim}
          dispatch={dispatch}
          index={index}
        />
      ))}
      <Grid item xs={12}>
        <Button
          onClick={useCallback(
            () => dispatch({ type: "addClaim" }),
            [dispatch]
          )}
          startIcon={<AddIcon />}
        >
          Tilføj faktura/krav
        </Button>
      </Grid>
    </>
  );
});
