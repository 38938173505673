import { Logo } from "../components/logo";
import blueBackgroundImage from "../right2.jpg";
import purbleBackgroundImage from "../left2.jpg";
import logo from "../nuna_logo.png";
import "./page.css";
import payLogos from "../pay_logos.png";
export const Page = ({
  children,
  background = "BLUE",
  maxWidth,
  showPayLogo,
  transparent,
}: {
  children: JSX.Element;
  background?: "BLUE" | "PURPLE";
  maxWidth?: string | number;
  showPayLogo?: boolean;
  transparent?: boolean;
}): JSX.Element => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",

        background: `url(${
          background === "BLUE" ? blueBackgroundImage : purbleBackgroundImage
        }) center center no-repeat fixed`,
        backgroundSize: "cover",
      }}
    >
      <div
        style={{
          flex: 1,

          paddingTop: "5em",
          paddingBottom: "5em",
        }}
      >
        <Logo />

        <div
          className="card"
          style={{
            width: "75%",
            marginLeft: "auto",
            marginRight: "auto",
            padding: "1em",
            color: "#000",
            maxWidth,
            backgroundColor: transparent ? "rgba(255, 255, 255, 0.8)" : "auto",
          }}
        >
          {children}
        </div>
        {showPayLogo ? (
          <img
            src={payLogos}
            height={30}
            alt="Betalingsmidler"
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
              marginTop: 5,
            }}
          />
        ) : null}
      </div>
      <div style={{ marginLeft: "auto", textAlign: "right" }}>
        <img src={logo} alt="Nuna Logo" style={{ height: 50 }} />
      </div>
    </div>
  );
};
