import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FormattedMessage } from "react-intl";

export const TermsDialog = React.memo(
  ({ open, onOk }: { open: boolean; onOk: () => void }): JSX.Element => {
    return (
      <Dialog open={open} onClose={onOk}>
        <DialogTitle>
          <FormattedMessage defaultMessage="1 Vilkår" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            1.1 Følgende vilkår er gældende mellem Nuna Advokater ApS (herefter
            ”Nuna Advokater”) og klienten (herefter ”Kreditor”) der sender en
            inkassosag til inddrivelse ved Nuna Advokater. <br />
            1.2 Disse vilkår er gældende fra den 1. januar 2022 og revideres en
            gang årligt 1. januar. <br />
            1.3 Vores inddrivelse udøves i overensstemmelse med de advokatetiske
            regler og grønlandsk ret. <br />
            1.4 I overensstemmelse med Advokatsamfundets regler og Nuna
            Advokaters procedurer undersøges det ved modtagelsen af en sag, om
            der består interessekonflikter, der kan forhindre os i at påtage os
            sagen. Ved indsendelsen af en sag er opdraget først påtaget, når der
            afsendes en bekræftelse på at sagen er oprettet.
            <h3>2 Fotrolighed og persondata</h3>
            2.1 Som led i vores rådgivning behandles bl.a. personoplysninger.
            Vores behandling sker i henhold til den til enhver tid gældende
            lovgivning i Grønland om behandling af persondata. <br />
            2.2 Alle oplysninger modtaget fra eller om klienter, som vi
            modtager, behandles fortroligt. Alle i Nuna Advokater og Nuna
            Advokaters samarbejdspartnere er pålagt tavshedspligt i relation til
            sagsbehandling.
            <h3>3 Fordringer der inddrives</h3>
            3.1 Kreditor er ansvarlig for at ethvert krav der sendes til
            inddrives, overholder følgende betingelser: <br />
            3.1.1 Krav til inddrivelse skal;
            <ol type="a">
              <li>være forfaldet til betaling,</li>
              <li>være fuldt dokumenteret.</li>
            </ol>
            3.1.2 Krav til inddrivelse må ikke;
            <ol type="a">
              <li>være bestridt af debitor,</li>
              <li>være ældre end 5 år.</li>
            </ol>
            3.2 Kreditors er ansvarlig for at medsende al relevant
            dokumentation. Manglende dokumentation kan medføre yderligere
            omkostninger ved inddrivelsen.
            <h3>4 Inddrivelsen</h3>
            4.1 Kreditor giver tilladelse til at Nuna Advokater kan forestå den
            fulde inddrivelse af kravet ved at opnå fundament igennem forlig
            eller dom, hvorefter sagen sendes i fogedretten med henblik på
            udlæg. Kreditor giver tilladelse til at Nuna Advokater kan
            iværksætte en afdragsordning på baggrund af forligstilbud fra
            debitor. <br />
            4.2 Kreditor afholder selv enhver positiv omkostning i forbindelse
            med inddrivelsen, såsom retsafgifter, gebyr ved indbetalinger eller
            indløsning. <br />
            4.3 Inddrivelsen sker på baggrund af den af Nuna Advokater
            tilrettelagt proces, hvorved der alene opkræves rykkergebyrer og
            procesrente i henhold til renteloven på baggrund af det materiale
            der sendes ind. <br />
            4.4 Indsigt i sagsstatus skal ske ved at rette henvendelse til Nuna
            Advokater.
            <h3>5 Ansvar</h3>
            5.1 Vores ansvar er begrænset til DKK 5.000 pr. sag.
            <h3>6 Honorar</h3>
            6.1 Vores honorar er baseret pr. sagsskridt, hvorved der opkræves
            honorar for gennemgang og oprettelse af sagen, pr. skrivelse,
            sagsskridt, processkrift og øvrige ekspeditioner. Taksterne
            revideres en gang årligt. Ønsker kreditor de nærmere sagsskridt
            specificeret, skal Nuna Advokater kontaktes herom. <br />
            6.2 Betaling sker pr. sag ved afslutning af sagen.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onOk} color="primary" autoFocus>
            <FormattedMessage defaultMessage="Ok" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);
