import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./app";
import reportWebVitals from "./reportWebVitals";
import { IntlProvider } from "react-intl";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "date-fns";
import daLocale from "date-fns/locale/da";

ReactDOM.render(
  <React.StrictMode>
    <IntlProvider defaultLocale="da" locale="da">
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={daLocale}>
        <App />
      </MuiPickersUtilsProvider>
    </IntlProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
