import { Button } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { ControlledTextfield } from "../components/inputs";
import { Page } from "../components/page";
import axios from "axios";
import { useState } from "react";

export const CreatePayment = (): JSX.Element => {
  const intl = useIntl();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [submitError, setSubmitError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = (data: any) => {
    setSubmitError(false);

    setSubmitting(true);

    axios
      .post<{ error: true } | { error: false; url: string }>(
        "/api/create-payment",
        {
          ...data,
        }
      )
      .then(function (response) {
        if (!response.data.error) {
          window.location.href = response.data.url;
        }
      })
      .catch(function (error) {
        setSubmitting(false);
        setSubmitError(true);
        console.log(error);
      });
  };

  return (
    <Page background="PURPLE" maxWidth={600} showPayLogo transparent>
      <form onSubmit={handleSubmit(onSubmit)} id="createPayment">
        <h2 style={{ margin: 0, marginBottom: 10 }}>
          <FormattedMessage defaultMessage="Betal med det samme" />
        </h2>
        <p>
          Hvis du har modtaget et brev fra os om at du skylder penge, har du her
          mulighed for at betale hele din gælden på én gang. Udfyld dine
          oplysninger nedenfor og vælg dernæst "Gå til betaling". Du kan betale
          med kort eller mobilepay.
        </p>
        <p>
          Du kan også gebyrfrit benytte kodelinjen i brevet du har modtaget fra
          os, til at betale din gæld i din netbank. Hvis ud har spørgsmål, kan
          du altid kontakte os på inkasso@nuna-law.gl eller på telefon 32 13 70
          inden for vores åbningstid.
        </p>

        <h2 style={{ margin: 0, marginBottom: 10 }}>
          <FormattedMessage defaultMessage="Dine oplysninger" />
        </h2>
        <ControlledTextfield
          errors={errors}
          rules={{ required: true }}
          name="creditor"
          control={control}
          defaultValue=""
          label="Hvem har du gæld til?"
        />

        <ControlledTextfield
          type="number"
          errors={errors}
          rules={{ required: true }}
          name="amount"
          control={control}
          defaultValue=""
          label="Beløb"
        />

        <ControlledTextfield
          errors={errors}
          rules={{ required: true }}
          name="name"
          control={control}
          defaultValue=""
          label="Navn"
        />

        <ControlledTextfield
          errors={errors}
          rules={{ required: true }}
          name="address"
          control={control}
          defaultValue=""
          label="Adresse"
        />

        <ControlledTextfield
          errors={errors}
          rules={{ required: true }}
          name="postalCode"
          control={control}
          defaultValue=""
          label="Postnummer"
        />

        <ControlledTextfield
          rules={{
            pattern: {
              value:
                /^(((0[1-9]|[12][0-9]|30)(0[13-9]|1[012])|31(0[13578]|1[02])|(0[1-9]|1[0-9]|2[0-8])02)[0-9]{2}|2902((([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))(-|\s)?\d{4}/,
              message: "Skal indtastes på formen: 010110-1001",
            },
            required: true,
          }}
          errors={errors}
          name="cpr"
          control={control}
          defaultValue=""
          label="CPR"
        />

        <ControlledTextfield
          errors={errors}
          rules={{ required: true }}
          name="phoneNumber"
          control={control}
          defaultValue=""
          label="Telefonnummer"
        />

        <ControlledTextfield
          errors={errors}
          rules={{ required: true }}
          name="email"
          control={control}
          defaultValue=""
          label="Email"
        />

        {submitError
          ? intl.formatMessage({
              defaultMessage: "Der skete en fejl, prøv igen senere",
            })
          : null}
        <Button type="submit" variant="contained" color="primary">
          {submitting
            ? intl.formatMessage({
                defaultMessage: "Arbejder...",
              })
            : intl.formatMessage({
                defaultMessage: "Gå til betalling",
              })}
        </Button>
      </form>
    </Page>
  );
};
