import React, { ReactNode, useCallback, useEffect, useState } from "react";
import {
  Divider,
  FormControl,
  FormHelperText,
  InputBase,
  makeStyles,
  MenuItem,
  Paper,
  RadioGroup,
  Select,
  TextField,
  withStyles,
} from "@material-ui/core";
import {
  Controller,
  FieldValues,
  Control,
  ValidationRule,
  FieldError,
  DeepMap,
} from "react-hook-form";
import { Autocomplete } from "@material-ui/lab";
import { FormattedMessage, useIntl } from "react-intl";
import { KeyboardDatePicker } from "@material-ui/pickers";

export const ControlledTextfield = React.memo(
  ({
    control,
    defaultValue,
    name,
    label,
    rules,
    errors,
    type,
    ...rest
  }: {
    control: Control<FieldValues>;
    defaultValue: string;
    name: string;
    label: string;
    type?: string;
    rules?: {
      [key: string]: ValidationRule | ((value: any) => boolean | string);
    };
    errors: DeepMap<FieldValues, FieldError>;
  }) => {
    const currentError = errors[name];
    return (
      <Controller
        {...rest}
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field }) => (
          <TextField
            {...field}
            type={type}
            label={label}
            variant="outlined"
            error={!!currentError}
            helperText={
              !!currentError
                ? currentError.type === "required"
                  ? "Skal udfyldes"
                  : currentError.message
                : undefined
            }
            fullWidth
          />
        )}
      />
    );
  }
);

export const ControlledChoiceField = React.memo(
  ({
    control,
    defaultValue,
    name,
    rules,
    errors,
    children,
    ...rest
  }: {
    control: Control<FieldValues>;
    defaultValue: string;
    name: string;
    rules?: {
      [key: string]: ValidationRule | ((value: any) => boolean | string);
    };
    errors: DeepMap<FieldValues, FieldError>;
    children: ReactNode;
  }) => {
    const currentError = errors[name];
    return (
      <Controller
        {...rest}
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field }) => (
          <FormControl component="fieldset" error={!!currentError}>
            <RadioGroup {...field}>{children}</RadioGroup>
            <FormHelperText>
              {!!currentError
                ? currentError.type === "required"
                  ? "Skal udfyldes"
                  : currentError.message
                : undefined}
            </FormHelperText>
          </FormControl>
        )}
      />
    );
  }
);
export const ControlledDateField = React.memo(
  ({
    control,
    defaultValue,
    name,
    rules,
    errors,
    label,
    ...rest
  }: {
    control: Control<FieldValues>;
    defaultValue: string | null;
    name: string;
    label: string;
    rules?: {
      [key: string]: ValidationRule | ((value: any) => boolean | string);
    };
    errors: DeepMap<FieldValues, FieldError>;
  }) => {
    const currentError = errors[name];
    return (
      <Controller
        {...rest}
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field }) => (
          <KeyboardDatePicker
            {...field}
            format="dd/MM/yyyy"
            margin="normal"
            label={label}
            inputVariant="outlined"
            error={!!currentError}
            helperText={
              !!currentError
                ? currentError.type === "required"
                  ? "Skal udfyldes"
                  : currentError.message
                : undefined
            }
            fullWidth
          />
        )}
      />
    );
  }
);

export default function CVRAutoComplete({
  control,
  defaultValue,
  name,
  label,
  rules,
  errors,
  ...rest
}: {
  control: Control<FieldValues>;
  defaultValue: string;
  name: string;
  label: string;
  rules?: {
    [key: string]: ValidationRule | ((value: any) => boolean | string);
  };
  errors: DeepMap<FieldValues, FieldError>;
}) {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = useState([]);

  useEffect(() => {
    let active = true;

    (async () => {
      if (inputValue) {
        const response = await fetch(`/api/cvr-search?cvr=${inputValue}`);
        const companies = await response.json();
        if (active) {
          setOptions(
            companies.map((entry: any) => ({
              ...entry,
              value: entry.cvrNummer,
              label: `${entry.cvrNummer}`,
            }))
          );
        }
      }
    })();

    return () => {
      active = false;
    };
  }, [inputValue]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);
  const currentError = errors[name];
  return (
    <Controller
      {...rest}
      rules={rules}
      render={({ field: { onChange, ...renderRest } }) => (
        <Autocomplete
          freeSolo
          disableClearable
          options={options}
          getOptionLabel={(option) => option.label || ""}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          renderOption={(option) => (
            <div>
              {option.cvrNummer} <br />
              <strong>{option.virksomhedMetadata.nyesteNavn.navn}</strong>
            </div>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant="outlined"
              type="number"
              error={!!currentError}
              helperText={
                !!currentError
                  ? currentError.type === "required"
                    ? "Skal udfyldes"
                    : currentError.message
                  : undefined
              }
              onBlur={() => {
                onChange({
                  value: inputValue,
                  cvrNummer: inputValue,
                  label: inputValue,
                });
              }}
            />
          )}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          onChange={(e, data) => onChange(data)}
          {...renderRest}
        />
      )}
      defaultValue={defaultValue}
      name={name}
      control={control}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 3,
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    height: 30,
    borderColor: "rgba(0, 0, 0, 0.23)",
    color: "rgba(0, 0, 0, 0.87)",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  divider: {
    height: 20,
    margin: 4,
  },
}));

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "0px",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    color: "currentColor",
  },
}))(InputBase);

export function DescriptionSelect({
  value,
  onChange,
  error,
}: {
  value: { variant: "invoice" | "other"; value: string } | null;
  onChange: (value: { variant: "invoice" | "other"; value: string }) => void;
  error: boolean;
}) {
  const classes = useStyles();
  const [variant, setVariant] = useState<"invoice" | "other">("invoice");
  const [inputValue, setInputValue] = useState<string>("");
  const handleVariantChange = useCallback(
    (
      event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>
    ) => {
      setVariant(event.target.value as "invoice" | "other");
    },
    []
  );
  useEffect(() => {
    if (inputValue !== value?.value || variant !== value?.variant) {
      onChange({ value: inputValue, variant });
    }
  }, [inputValue, onChange, value?.value, value?.variant, variant]);

  const intl = useIntl();

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value);
    },
    []
  );
  return (
    <Paper
      className={classes.root}
      variant="outlined"
      style={error ? { borderColor: "red" } : undefined}
    >
      <Select
        input={<BootstrapInput />}
        value={variant}
        onChange={handleVariantChange}
        style={{ width: 100 }}
      >
        <MenuItem value={"invoice"}>
          <FormattedMessage defaultMessage="Fakturanr." />
        </MenuItem>
        <MenuItem value={"other"}>
          <FormattedMessage defaultMessage="Andet" />
        </MenuItem>
      </Select>
      <Divider className={classes.divider} orientation="vertical" />
      <InputBase
        error={error}
        className={classes.input}
        placeholder={
          variant === "invoice"
            ? intl.formatMessage({ defaultMessage: "Indtast Fakturanummer" })
            : intl.formatMessage({ defaultMessage: "Indtast beskrivelse" })
        }
        inputProps={{ "aria-label": "search google maps" }}
        value={inputValue}
        onChange={handleInputChange}
      />
    </Paper>
  );
}
