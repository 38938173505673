import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  link: {
    color: "white",
    textDecoration: "none",
    ":visited": {
      color: "white",
    },
  },
});

export const Logo = (): JSX.Element => {
  const classes = useStyles();
  return (
    <div
      style={{
        textShadow: "2px 3px rgba(0, 0, 0, 0.2)",
        position: "absolute",
        left: 20,
        top: 0,
        color: "#fff",
      }}
    >
      <a href="/" className={classes.link}>
        <h1
          style={{
            display: "inline",
          }}
        >
          Inkasso.gl
        </h1>
      </a>{" "}
      - en del af{" "}
      <a
        href="https://nuna-law.gl"
        target="_blank"
        style={{ fontWeight: "bold", color: "#FFF", textDecoration: "none" }}
        rel="noreferrer"
      >
        Nuna Advokater
      </a>
    </div>
  );
};
