import Grid from "@material-ui/core/Grid";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  TextField,
} from "@material-ui/core";
import React, {
  useCallback,
  useState,
  useReducer,
  useMemo,
  useEffect,
} from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Controller, useForm, useWatch } from "react-hook-form";
import CVRAutoComplete, { ControlledTextfield } from "../components/inputs";
import { Claims } from "../components/claims";
import { Action, Claim, Payment } from "../components/types";
import axios from "axios";
import { Page } from "../components/page";
import { postalCodes } from "../postal-codes";
import { TermsDialog } from "../components/terms-dialog";

const EMAIL_REGEX =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

const EMPTY_PAYMENT: Payment = {
  type: "",
  date: null,
  amount: null,
};

const EMPTY_CLAIM = {
  descriptionType: "invoice" as const,
  description: "",
  principal: null,
  invoiceDate: null,
  appendices: [],
  payments: [],
};

const initialState: Claim[] = [
  {
    ...EMPTY_CLAIM,
  },
];

const reducer = (state: Claim[], action: Action) => {
  switch (action.type) {
    case "addClaim": {
      const newState = [...state];
      newState.push({ ...EMPTY_CLAIM });
      return newState;
    }

    case "updateClaim": {
      const newState = [...state];
      const copy = { ...state[action.index], [action.field]: action.newValue };
      newState[action.index] = copy;
      return newState;
    }

    case "addPayment": {
      const newState = [...state];
      const copy = { ...newState[action.claimIndex] };
      copy.payments = [...copy.payments, { ...EMPTY_PAYMENT }];
      newState[action.claimIndex] = copy;
      return newState;
    }

    case "updatePayment": {
      const newState = [...state];
      const copy = { ...newState[action.claimIndex] };
      const paymentCopy = {
        ...copy.payments[action.index],
        [action.field]: action.newValue,
      };
      copy.payments[action.index] = paymentCopy;
      newState[action.claimIndex] = copy;
      return newState;
    }

    case "reset":
      return [...initialState];

    default:
      return state;
  }
};

export const NewCase = (): JSX.Element => {
  const [claims, dispatch] = useReducer(reducer, initialState);
  //const [showCreditor, setShowDebitor] = useState<boolean | null>(null);
  const intl = useIntl();
  const [showClaimValidation, setShowClaimValidation] = useState(false);

  const claimsValid = useMemo(() => {
    return (
      claims.length &&
      claims[0].appendices.length &&
      !claims.some(
        (claim) =>
          !claim.description ||
          !claim.invoiceDate ||
          !claim.principal ||
          (claim.payments.length &&
            claim.payments.some(
              (payment) => !payment.type || !payment.date || !payment.amount
            ))
      )
    );
  }, [claims]);

  const { control, handleSubmit, formState, reset, getValues, setValue } =
    useForm();
  const { errors } = formState;
  const [submitError, setSubmitError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [done, setDone] = useState(false);
  const [creatorCity, setCreatorCity] = useState<string>();
  const [debtorCity, setDebtorCity] = useState<string>();
  console.log(errors);
  /*  const handleShowDebitor = useCallback((_event: unknown, value: string) => {
    setShowDebitor(value === "yes");
  }, []); */

  const onSubmit = (data: any) => {
    console.log(
      claims.length,
      claims[0].appendices.length,
      !claims.some(
        (claim) =>
          !claim.description ||
          !claim.invoiceDate ||
          !claim.principal ||
          (claim.payments.length &&
            claim.payments.some(
              (payment) => !payment.type || !payment.date || !payment.amount
            ))
      )
    );
    setSubmitError(false);
    if (!claimsValid) {
      setShowClaimValidation(true);
      return;
    }
    setSubmitting(true);
    setShowClaimValidation(false);

    axios
      .post("/api/submit-case", {
        data: {
          ...data,
          creatorIdentifier: data.creatorIdentifier?.cvrNummer || "",
        },
        claims: claims.map((claim) => ({
          ...claim,
          description:
            claim.descriptionType === "invoice"
              ? `Fakturanr. ${claim.description}`
              : claim.description,
        })),
      })
      .then(function (response) {
        setSubmitting(false);
        setDone(true);
      })
      .catch(function (error) {
        setSubmitting(false);
        setSubmitError(true);
        console.log(error);
      });
  };

  const handleNewCase = useCallback(() => {
    dispatch({ type: "reset" });
    setDone(false);
    reset({
      ...getValues(),
      debtorName: "",
      debtorAddress: "",
      debtorPostalCode: "",
      debtorPhone: "",
      debtorMail: "",
      debtorIndentifier: "",
    });
  }, [getValues, reset]);

  const [termsDialogOpen, setTermsDialogOpen] = useState(false);
  const handleTermLinkClick = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    setTermsDialogOpen(true);
  }, []);
  const vatPersonNumberValidator = useCallback(
    (value: string) => {
      if (!value) {
        return true;
      }
      const cleaned = value.replace("-", "");
      if (cleaned.indexOf("-") > -1) {
        return intl.formatMessage({
          defaultMessage: "Skal indholde 6 eller 10 cifre og én bindestreg",
        });
      }
      try {
        parseInt(cleaned);

        if (cleaned.length !== 6 && cleaned.length !== 10) {
          return intl.formatMessage({
            defaultMessage: "Skal indholde 6 eller 10 cifre og én bindestreg",
          });
        }
      } catch {
        return intl.formatMessage({
          defaultMessage: "Må kun indeholde tal og maximum én bindestreg",
        });
      }
      return true;
    },
    [intl]
  );

  const creatorPostalCode = useWatch({ name: "creatorPostalCode", control });
  useEffect(() => {
    const cityForPostalCode = postalCodes[creatorPostalCode];
    if (cityForPostalCode) {
      setCreatorCity(cityForPostalCode);
    }
  }, [creatorPostalCode]);

  const debtorPostalCode = useWatch({ name: "debtorPostalCode", control });
  useEffect(() => {
    const cityForPostalCode = postalCodes[debtorPostalCode];
    if (cityForPostalCode) {
      setDebtorCity(cityForPostalCode);
    }
  }, [debtorPostalCode]);

  const creatorIdentifier = useWatch({ name: "creatorIdentifier", control });
  useEffect(() => {
    if (creatorIdentifier && creatorIdentifier.virksomhedMetadata) {
      setValue(
        "creatorName",
        creatorIdentifier.virksomhedMetadata.nyesteNavn.navn
      );
      const { vejnavn, husnummerFra, etage, sidedoer, postnummer } =
        creatorIdentifier.virksomhedMetadata.nyesteBeliggenhedsadresse;
      setValue(
        "creatorAddress",
        `${vejnavn} ${husnummerFra} ${etage !== null ? etage : ""} ${
          sidedoer !== null ? sidedoer : ""
        }`.trim()
      );
      setValue("creatorPostalCode", postnummer);
    }
  }, [creatorIdentifier, setValue]);

  let content = null;
  if (done) {
    content = (
      <div>
        <h1>
          <FormattedMessage defaultMessage="Sagen er oprettet" />
        </h1>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleNewCase}
        >
          <FormattedMessage defaultMessage="Opret ny sag?" />
        </Button>
      </div>
    );
  } else {
    content = (
      <form onSubmit={handleSubmit(onSubmit)} id="createform">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <h2 style={{ margin: 0, marginBottom: 10 }}>
              <FormattedMessage defaultMessage="Opret ny inkassosag" />
            </h2>
          </Grid>
          <Grid item lg={6}>
            <h4 style={{ margin: 0, marginBottom: 10 }}>
              <FormattedMessage defaultMessage="Jeres oplysninger" />
            </h4>
            <CVRAutoComplete
              rules={{
                required: true,
                pattern: /[0-9]{6}/,
                minLength: 6,
                maxLength: 6,
              }}
              errors={errors}
              name="creatorIdentifier"
              control={control}
              defaultValue=""
              label={intl.formatMessage({ defaultMessage: "CVR" })}
            />
            <ControlledTextfield
              errors={errors}
              rules={{ required: true }}
              name="creatorName"
              control={control}
              defaultValue=""
              label={intl.formatMessage({ defaultMessage: "Firmanavn" })}
            />

            <ControlledTextfield
              errors={errors}
              rules={{ required: true }}
              name="creatorAddress"
              control={control}
              defaultValue=""
              label={intl.formatMessage({
                defaultMessage: "Vejnavn + nr.",
              })}
            />
            <div style={{ height: 40 }}>
              <Grid item container spacing={0}>
                <Grid item xs={3}>
                  <ControlledTextfield
                    errors={errors}
                    rules={{
                      required: true,
                      maxLength: {
                        value: 4,
                        message: intl.formatMessage({
                          defaultMessage: "Maximum fire cifre",
                        }),
                      },
                    }}
                    name="creatorPostalCode"
                    control={control}
                    defaultValue=""
                    label={intl.formatMessage({ defaultMessage: "Postnummer" })}
                  />
                </Grid>
                <Grid item xs={9}>
                  <div style={{ paddingLeft: "0.5em" }}>
                    <TextField
                      value={creatorCity}
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            <ControlledTextfield
              rules={{ required: true }}
              errors={errors}
              name="creatorPhoneNumber"
              control={control}
              defaultValue=""
              label={intl.formatMessage({ defaultMessage: "Telefonnummer" })}
            />
            <ControlledTextfield
              rules={{
                required: true,
                pattern: {
                  value: EMAIL_REGEX,
                  message: intl.formatMessage({
                    defaultMessage: "Skal være en gyldig email adresse",
                  }),
                },
              }}
              errors={errors}
              name="creatorEmail"
              control={control}
              defaultValue=""
              label={intl.formatMessage({ defaultMessage: "Email" })}
            />

            <ControlledTextfield
              errors={errors}
              name="creatorReference"
              control={control}
              defaultValue=""
              label={intl.formatMessage({
                defaultMessage: "Jeres reference:",
              })}
            />
          </Grid>
          <Grid item lg={6}>
            <h4 style={{ margin: 0, marginBottom: 10 }}>
              <FormattedMessage defaultMessage="Debitor" />
            </h4>
            <ControlledTextfield
              rules={{
                validate: vatPersonNumberValidator,
              }}
              errors={errors}
              name="debtorIndentifier"
              control={control}
              defaultValue=""
              label={intl.formatMessage({ defaultMessage: "CPR/CVR" })}
            />
            <ControlledTextfield
              rules={{ required: true }}
              errors={errors}
              name="debtorName"
              control={control}
              defaultValue=""
              label={intl.formatMessage({ defaultMessage: "Navn" })}
            />
            <ControlledTextfield
              rules={{ required: true }}
              errors={errors}
              name="debtorAddress"
              control={control}
              defaultValue=""
              label={intl.formatMessage({
                defaultMessage: "Vejnavn + nr.",
              })}
            />
            <div style={{ height: 40 }}>
              <Grid item container spacing={0}>
                <Grid item xs={3}>
                  <ControlledTextfield
                    rules={{
                      required: true,
                      maxLength: {
                        value: 4,
                        message: intl.formatMessage({
                          defaultMessage: "Maximum fire cifre",
                        }),
                      },
                    }}
                    errors={errors}
                    name="debtorPostalCode"
                    control={control}
                    defaultValue=""
                    label={intl.formatMessage({ defaultMessage: "Postnummer" })}
                  />
                </Grid>
                <Grid item xs={9}>
                  <div style={{ paddingLeft: "0.5em" }}>
                    <TextField
                      value={debtorCity}
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            <ControlledTextfield
              errors={errors}
              name="debtorPhone"
              control={control}
              defaultValue=""
              label={intl.formatMessage({
                defaultMessage: "Telefonnummer",
              })}
            />
            <ControlledTextfield
              rules={{
                pattern: {
                  value: EMAIL_REGEX,
                  message: intl.formatMessage({
                    defaultMessage: "Skal være en gyldig email adresse",
                  }),
                },
              }}
              errors={errors}
              name="debtorMail"
              control={control}
              defaultValue=""
              label={intl.formatMessage({ defaultMessage: "Email" })}
            />
          </Grid>
          <Grid item xs={12}>
            <Claims
              claims={claims}
              dispatch={dispatch}
              showValidation={showClaimValidation}
            />
          </Grid>
          <Grid item xs={12}>
            {submitError ? (
              <h3 style={{ color: "red" }}>
                <FormattedMessage defaultMessage="Der er sket en fejl, prøv igen senere." />
              </h3>
            ) : null}
            <FormControlLabel
              control={
                <Controller
                  name="terms"
                  control={control}
                  rules={{ required: true }}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.field.value}
                      onChange={(e) => props.field.onChange(e.target.checked)}
                    />
                  )}
                />
              }
              label={
                <span style={errors["terms"] ? { color: "red" } : undefined}>
                  <FormattedMessage defaultMessage="Jeg bekræfter at jeg har læst og godkender forretningsbetingelserne." />{" "}
                  <Link href="#" onClick={handleTermLinkClick}>
                    Se dem her
                  </Link>
                </span>
              }
            />
            <br />
            {errors["terms"] ? (
              <div style={{ color: "red", marginBottom: 20 }}>
                <FormattedMessage defaultMessage="Du skal godkende vores foretningsbetingelser." />
                <br />
              </div>
            ) : null}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={submitting}
            >
              {submitting
                ? intl.formatMessage({
                    defaultMessage: "Opretter...",
                  })
                : intl.formatMessage({
                    defaultMessage: "Indsend inkassosag",
                  })}
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }

  return (
    <Page transparent>
      <>
        {content}
        <TermsDialog
          open={termsDialogOpen}
          onOk={useCallback(() => setTermsDialogOpen(false), [])}
        />
      </>
    </Page>
  );
};
