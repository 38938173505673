/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import "./landing.css";
import { Logo } from "../components/logo";
//import { FormattedMessage } from "react-intl";
import logo from "../nuna_logo.png";
import { Link } from "react-router-dom";
import payLogos from "../pay_logos.png";
import { useCallback, useState } from "react";
import { PolicyDialog } from "../components/policy-dialog";
import { Link as MUILink } from "@material-ui/core";
import { BrowserView } from "react-device-detect";
import { ContactDialog } from "../components/contact-dialog";

export const Landing = (): JSX.Element => {
  const [termsDialogOpen, setTermsDialogOpen] = useState(false);
  const handleTermLinkClick = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    setTermsDialogOpen(true);
  }, []);
  const [contactDialogOpen, setContactDialogOpen] = useState(false);
  const handleContactLinkClick = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    setContactDialogOpen(true);
  }, []);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <div className="container">
        <div className="split left">
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Logo />
            <h1>Har du modtaget et brev eller en mail?</h1>
            <div style={{ marginBottom: "1em" }}>
              Betal din gæld eller opret en afdragsordning
            </div>
            <Link className="button" to="/ny-betaling">
              Betal nu
            </Link>

            <Link className="button" to="/ny-aftale">
              Opret afdragsordning
            </Link>
          </div>
          <div
            className="logo"
            style={{ marginRight: "auto", marginLeft: "auto" }}
          >
            <img src={payLogos} height={40} alt="Betalingsmidler" />
          </div>
        </div>
        <div className="split right">
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <BrowserView>
              <div style={{ position: "absolute", right: 20, top: 5 }}>
                <img src={logo} alt="Nuna Logo" style={{ height: 50 }} />
              </div>
            </BrowserView>
            <h1>Kreditor</h1>
            <div style={{ marginBottom: "1em" }}>
              Opret eller håndtér sager
            </div>{" "}
            <a
              href="https://kreditor.nuna-law.com/"
              className="button"
              target="_blank"
            >
              Kreditorweb 
            </a>
            <a href="#" className="button" onClick={handleContactLinkClick}>
              Få adgang til kreditorweb
            </a>
          </div>

          <div className="logo" style={{ marginLeft: "auto", fontSize: 10 }}>
            Nuna Avokater ApS
            <br />
            Qullilerfik 2, 6.
            <br />
            Postboks 59
            <br />
            3900 Nuuk
            <br />
            CVR nr. 12525125
            <br />
            <MUILink
              href="#"
              onClick={handleTermLinkClick}
              style={{
                color: "#FFF",
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              Privatlivspolitik
            </MUILink>
          </div>
        </div>
      </div>
      <PolicyDialog
        open={termsDialogOpen}
        onOk={useCallback(() => setTermsDialogOpen(false), [])}
      />
      <ContactDialog
        open={contactDialogOpen}
        onOk={useCallback(() => setContactDialogOpen(false), [])}
      />
    </div>
  );
};
