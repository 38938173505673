import { Button, CircularProgress } from "@material-ui/core";
import React, { useRef, useState } from "react";
import { useCallback } from "react";
import { UploadResponse } from "./types";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { FormattedMessage } from "react-intl";

const fileListToArray = (list: FileList | null): File[] => {
  const array: File[] = [];
  if (!list) {
    return array;
  }
  for (let i = 0; i < list.length; i++) {
    const file = list.item(i);
    if (file) {
      array.push(file);
    }
  }
  return array;
};

export const Upload = React.memo(function Upload({
  error,
  accept,
  multiple = true,
  onError,
  onFileUploaded,
  onUploadStart,
  onFileUploadCountChange,
}: {
  error: boolean;
  accept: string;
  multiple: boolean;
  onError?: (eror: any) => void;
  onFileUploaded: (response: UploadResponse) => void;
  onFileUploadCountChange?: (total: number) => void;
  onUploadStart?: (total: number) => void;
}): JSX.Element {
  //const [totalCount, setTotalCount] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<{
    [fileName: string]: { percentage: number; state: string };
  }>({});

  const fileInputRef = useRef<HTMLInputElement>(null);

  const sendRequest = useCallback(
    (file) => {
      return new Promise((resolve, reject) => {
        const req = new XMLHttpRequest();
        req.upload.addEventListener("progress", (event) => {
          if (event.lengthComputable) {
            const copy = { ...uploadProgress };
            copy[file.name] = {
              percentage: (event.loaded / event.total) * 100,
              state: "pending",
            };
            //console.log(copy[file.name].percentage)
            const uploadCount = Object.values(copy).reduce(
              (totalCount, progress) =>
                progress.percentage === 100 ? totalCount + 1 : totalCount,
              0
            );
            if (onFileUploadCountChange) {
              onFileUploadCountChange(uploadCount);
            }

            setUploadProgress(copy);
          }
        });

        req.upload.addEventListener("load", () => {
          const copy = { ...uploadProgress };
          copy[file.name] = { percentage: 100, state: "done" };
          setUploadProgress(copy);
          resolve(req.response);
        });

        req.upload.addEventListener("error", () => {
          const copy = { ...uploadProgress };
          copy[file.name] = { percentage: 0, state: "error" };
          setUploadProgress(copy);
          reject(req.response);
        });

        req.addEventListener("readystatechange", () => {
          if (req.readyState === 4) {
            if (req.status === 200) {
              const response = JSON.parse(req.responseText);
              onFileUploaded(response.data[0]);
            } else {
              reject(req.response);
            }
          }
        });

        const formData = new FormData();
        formData.append("files", file, file.name);
        req.open("POST", "/api/upload-appendice");
        req.send(formData);
      });
    },
    [onFileUploadCountChange, onFileUploaded, uploadProgress]
  );

  const uploadFiles = useCallback(
    async (files) => {
      //setTotalCount(files.length);
      setUploading(true);
      setUploadProgress({});
      if (onUploadStart) {
        onUploadStart(files.length);
      }
      try {
        const promises = files.map((file: any) => sendRequest(file));

        await Promise.all(promises);
        setUploading(false);
      } catch (e) {
        if (onError) {
          onError(e);
        }
        setUploading(false);
        setUploadProgress({});
      }
    },
    [onError, onUploadStart, sendRequest]
  );

  const handleAddFileFabClick = useCallback(() => {
    if (uploading) {
      return;
    }
    fileInputRef.current?.click();
  }, [uploading]);

  const handleFilesAdded = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      if (uploading) {
        return;
      }
      const { files } = event.currentTarget;
      const array = fileListToArray(files);
      uploadFiles(array);
    },
    [uploadFiles, uploading]
  );

  return (
    <span>
      <span style={error ? { border: "2px solid red" } : undefined}>
        <Button onClick={handleAddFileFabClick} startIcon={<CloudUploadIcon />}>
          Tilføj bilag
        </Button>
      </span>

      {error ? (
        <div style={{ color: "red" }}>
          <FormattedMessage defaultMessage="Skal tilføjes" />
        </div>
      ) : undefined}
      {uploading ? <CircularProgress size={24} /> : null}
      <div style={{ display: "none" }}>
        <input
          ref={fileInputRef}
          name="appendices"
          accept={accept}
          className="FileInput"
          multiple={multiple}
          type="file"
          onChange={handleFilesAdded}
        />
      </div>
    </span>
  );
});
