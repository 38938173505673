import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Landing } from "./pages/landing";
import { NewCase } from "./pages/new-case";
import { CreatePayment } from "./pages/create-payment";
import { CreateInstallment } from "./pages/create-installment";

export const Routes = (): JSX.Element => {
  return (
    <Router>
      <Switch>
        <Route path="/ny-betaling">
          <CreatePayment />
        </Route>
        <Route path="/ny-aftale">
          <CreateInstallment />
        </Route>
        <Route path="/ny-inkasso">
          <NewCase />
        </Route>
        <Route path="/">
          <Landing />
        </Route>
      </Switch>
    </Router>
  );
};
