import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FormattedMessage } from "react-intl";

export const PolicyDialog = React.memo(
  ({ open, onOk }: { open: boolean; onOk: () => void }): JSX.Element => {
    return (
      <Dialog open={open} onClose={onOk}>
        <DialogTitle>
          <FormattedMessage defaultMessage="1 FORMÅL OG ANSVARLIG" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            1.1 Denne politik regulerer rammerne for behandling af
            personoplysninger hos Nuna Advokater A/S (herefter benævnt ”Nuna
            Advokater”). <br />
            1.2 Nuna Advokater er den dataansvarlige for de oplysninger som Nuna
            Advokater behandlinger om dig. Du kan kontakte os via nedenstående:
            Nuna Advokater A/S Qullilerfik 2, 6. 3900 Nuuk Grønland CVR-nr.
            12525125 Email: email@nuna-law.gl Tlf. +299 32 13 70 <br />
            <h3>2 ANVENDELSESOMRÅDE</h3>
            2.1 Denne persondatapolitik finder anvendelse på alle behandlinger
            af personoplysninger som Nuna Advokater foretager sig for klienter
            og modparter. <br />
            2.2 Rådgivning
            <br />
            Ved rådgivning, modtager Nuna Advokater personoplysninger fra vores
            klienter, modparter, myndigheder, domstolene, offentlige registre og
            andre rådgivere. Disse personoplysninger opbevares digitalt og i
            nogle tilfælde fysisk og indeholder almindelige personoplysninger,
            såsom navn og kontaktinformation eller andre oplysninger af særlig
            kategori, f.eks. CPR-numre, oplysninger om lovovertrædelser,
            helbreds- og fagforeningsforhold. Oplysningerne kan vedrøre vores
            klienter eller andre personer, som indgår i de pågældende sager. 2.3
            Inkasso
            <br />
            Vi modtager oplysninger fra vores klienter om gældsforhold. Dette
            omfatter almindelige personoplysninger, jf. ovenfor, samt
            oplysninger om om eventuelle sikkerheder. Herudover kan vi modtage
            CPR-numre, oplysninger om oplysninger om lovovertrædelser og
            helbredsoplysninger hvis det er relevant for sagen.
            <br />
            2.4 Når vi opretter inkassosagen, foretager vi en vurdering af
            oplysninger vi har modtaget, og validerer disse ved kontrol af
            offentligt tilgængelige registre. Dette omfatter f.eks.
            adresseoplysninger via CPR, søgning af telefonnummer, indhentning af
            udskrifter fra personbogen ved retten. <br />
            <h3>3 VIDEREGIVELSE</h3>
            3.1 Vi videregiver alene personoplysninger, såfremt det er
            nødvendigt, for at vi kan varetage vores klienters interesser. En
            sådan varetagelse vil typisk omfatte videregivelse til domstole,
            politi, offentlige myndigheder, modparter og øvrige
            partsrepræsentanter.
            <br />
            3.2 I sager omfattet af hvidvask, videregives personoplysninger
            alene såfremt vi er forpligtet til dette efter hvidvaskloven.
            <br />
            3.3 Vi har etableret passende tekniske og organisatoriske
            sikkerhedsforanstaltninger til sikring mod tab, ødelæggelse,
            uautoriseret adgang til oplysninger som vi er dataansvarlig for. Kun
            personer, som har et legitimt behov for at behandle
            personoplysninger til de ovennævnte formål, der har adgang til
            disse.
            <h3>4 OPBEVARING OG SLETNING</h3>
            4.1 Vi opbevarer personoplysninger under sagens behandling og op til
            fem år efter at sagen er afsluttet, hvorefter de slettes.
            <br />
            4.2 Efter en konkret vurdering kan personoplysninger opbevares i op
            til 10 år efter sagens afslutning, såfremt vi vurderer at der er en
            konkret retlig interesse heri. <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onOk} color="primary" autoFocus>
            <FormattedMessage defaultMessage="Ok" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);
