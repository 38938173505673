import { Button, FormControlLabel, Grid, Radio } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import {
  ControlledChoiceField,
  ControlledDateField,
  ControlledTextfield,
} from "../components/inputs";
import { Page } from "../components/page";
import axios from "axios";
import { useState } from "react";
import { isMobile } from "react-device-detect";

export const CreateInstallment = (): JSX.Element => {
  const intl = useIntl();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [submitError, setSubmitError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [done, setDone] = useState(false);

  const onSubmit = (data: any) => {
    setSubmitError(false);

    setSubmitting(true);

    axios
      .post<{ error: true } | { error: false; url: string }>(
        "/api/create-installment",
        {
          ...data,
        }
      )
      .then(function (response) {
        if (!response.data.error) {
          setDone(true);
        }
      })
      .catch(function (error) {
        setSubmitting(false);
        setSubmitError(true);
        console.log(error);
      });
  };

  if (done) {
    return (
      <Page background="PURPLE" maxWidth={600} showPayLogo transparent>
        <div>
          <FormattedMessage
            defaultMessage="Din anmodning er modtaget."
            tagName="h1"
          />
          <FormattedMessage
            tagName="p"
            defaultMessage="Vi har modtaget din anmodning om oprettelse af en afdragsordning. Husk at vi også skal modtage et underskrevet frivilligt forlig fra dig. Dette modtog sammen med brevet fra os."
          />
          <FormattedMessage
            tagName="p"
            defaultMessage="Vi sender en bekræftelse når vi har behandlet din anmodning om afdragsordning."
          />
          <FormattedMessage
            tagName="p"
            defaultMessage="Du modtager en kvittering pr. email for din anmodning"
          />
        </div>
      </Page>
    );
  }

  return (
    <Page background="PURPLE" maxWidth={600} showPayLogo transparent>
      <form onSubmit={handleSubmit(onSubmit)} id="createform">
        <h2 style={{ margin: 0, marginBottom: 10 }}>
          <FormattedMessage defaultMessage="Betal over flere gange" />
        </h2>
        <p>
          Hvis du ønsker at afdrage på din gæld, kan vi tilbyde dig en
          afdragsordning. Betingelsen for en afdragsordning er at du tilmelder
          den til betalingsservice og at du underskriver et frivilligt forlig.
          Du skal blot angive dine oplysninger nedenfor, så sørger vi for at
          tilmelde dig.
        </p>
        <p>
          Det er vigtigt at du overholder afdragsordningen. Du skal derfor
          kontakte os, hvis der sker ændringer i dine forhold som gør, at du
          ikke længere kan betale. Hvis ud har spørgsmål, kan du altid kontakte
          os på inkasso@nuna-law.gl eller på telefon 32 13 70 inden for vores
          åbningstid.
        </p>

        <ControlledTextfield
          errors={errors}
          rules={{ required: true }}
          name="name"
          control={control}
          defaultValue=""
          label="Navn"
        />

        <ControlledTextfield
          errors={errors}
          rules={{ required: true }}
          name="address"
          control={control}
          defaultValue=""
          label="Adresse"
        />

        <ControlledTextfield
          errors={errors}
          rules={{ required: true }}
          name="postalCode"
          control={control}
          defaultValue=""
          label="Postnummer"
        />

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <ControlledTextfield
              errors={errors}
              rules={{ required: true }}
              name="phoneNumber"
              control={control}
              defaultValue=""
              label={isMobile ? "Tlf" : "Telefonnummer"}
            />
          </Grid>
          <Grid item xs={8}>
            <ControlledTextfield
              errors={errors}
              rules={{ required: true }}
              name="email"
              control={control}
              defaultValue=""
              label="Email"
            />
          </Grid>
        </Grid>

        <ControlledTextfield
          rules={{
            pattern: {
              value:
                /^(((0[1-9]|[12][0-9]|30)(0[13-9]|1[012])|31(0[13578]|1[02])|(0[1-9]|1[0-9]|2[0-8])02)[0-9]{2}|2902((([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))(-|\s)?\d{4}/,
              message: "Skal indtastes på formen: 010110-1001",
            },
            required: true,
          }}
          errors={errors}
          name="cpr"
          control={control}
          defaultValue=""
          label="CPR"
        />

        <ControlledTextfield
          errors={errors}
          rules={{ required: true }}
          name="creditor"
          control={control}
          defaultValue=""
          label="Hvem har du gæld til?"
        />
        <FormattedMessage defaultMessage="Din bankkonto" />
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <ControlledTextfield
              type="number"
              errors={errors}
              rules={{ required: true }}
              name="reg"
              control={control}
              defaultValue=""
              label="Reg.nr."
            />
          </Grid>
          <Grid item xs={8}>
            <ControlledTextfield
              type="number"
              errors={errors}
              rules={{ required: true }}
              name="account"
              control={control}
              defaultValue=""
              label="Konto nr."
            />
          </Grid>
        </Grid>
        <FormattedMessage defaultMessage="Hvor ofte får du udbetalt løn? (Afdrag betales med lønudbetaling)" />
        <div style={{ paddingLeft: 20 }}>
          <ControlledChoiceField
            errors={errors}
            rules={{ required: true }}
            name="due"
            control={control}
            defaultValue=""
          >
            <FormControlLabel
              value="monthly"
              control={<Radio />}
              label="Én gang om måneden"
            />
            <FormControlLabel
              value="14days"
              control={<Radio />}
              label="Hver 14. dag"
            />
          </ControlledChoiceField>
        </div>
        <ControlledTextfield
          type="number"
          errors={errors}
          rules={{ required: true }}
          name="amount"
          control={control}
          defaultValue=""
          label="Hvor meget ønsker du at betale pr. afdrag?"
        />

        <ControlledDateField
          errors={errors}
          rules={{ required: true }}
          name="date"
          control={control}
          defaultValue={null}
          label="Hvornår ønsker du at starte med at afdrage?"
        />

        {submitError
          ? intl.formatMessage({
              defaultMessage: "Der skete en fejl, prøv igen senere",
            })
          : null}
        <Button type="submit" variant="contained" color="primary">
          {submitting
            ? intl.formatMessage({
                defaultMessage: "Arbejder...",
              })
            : intl.formatMessage({
                defaultMessage: "Anmod om adragsordning",
              })}
        </Button>
      </form>
    </Page>
  );
};
