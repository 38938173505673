import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormattedMessage } from "react-intl";
import { DialogContentText } from "@material-ui/core";

export const ContactDialog = React.memo(
  ({ open, onOk }: { open: boolean; onOk: () => void }): JSX.Element => {
    const [formData, setFormData] = useState({
      name: '',
      companyName: '',
      phoneNumber: '',
      email: ''
    });

    const [errors, setErrors] = useState({
      name: false,
      companyName: false,
      phoneNumber: false,
      email: false
    });

    const [formSubmitted, setFormSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);  // Til at håndtere indsendelse
    const [submitError, setSubmitError] = useState<string | null>(null);  // Til at vise fejlbesked

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    };

    const validateForm = () => {
      const newErrors = {
        name: formData.name === '',
        companyName: formData.companyName === '',
        phoneNumber: formData.phoneNumber === '',
        email: formData.email === ''
      };
      setErrors(newErrors);
      return !Object.values(newErrors).some(error => error === true);
    };

    const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault();

      if (!validateForm()) {
        return;
      }

      setIsSubmitting(true);
      setSubmitError(null); // Reset fejlbeskeden, når vi prøver at sende igen

      try {
        const response = await fetch('/api/contact', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
        if (response.ok) {
          setFormSubmitted(true);  // Skift til takkebesked
        } else {
          setSubmitError('Der opstod en fejl ved indsendelse af formularen. Prøv venligst igen.');
        }
      } catch (error) {
        console.error('Error:', error);
        setSubmitError('Der opstod en fejl ved indsendelse af formularen. Prøv venligst igen.');
      } finally {
        setIsSubmitting(false);
      }
    };

    return (
      <Dialog open={open} onClose={onOk}>
        <DialogTitle>
          {formSubmitted ? (
            <FormattedMessage defaultMessage="Tak for din interesse" />
          ) : (
            <FormattedMessage defaultMessage="Få adgang til Kreditorweb" />
          )}
        </DialogTitle>
        
        {formSubmitted ? (
          <DialogContent>
            <p>Tak for din interesse, vi vender tilbage hurtigst muligt.</p>
          </DialogContent>
        ) : (
          <form onSubmit={handleSubmit}>
            <DialogContent>
                <DialogContentText>
                  Skriv dine kontaktoplysninger, så ringer vi dig op. 
                </DialogContentText>
              {submitError && (
                <p style={{ color: 'red', fontWeight: 'bold' }}>{submitError}</p>
              )}
              <TextField
                fullWidth
                label="Navn"
                name="name"
                value={formData.name}
                onChange={handleChange}
                error={errors.name}
                helperText={errors.name ? "Navn skal udfyldes" : ""}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Firmanavn"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
                error={errors.companyName}
                helperText={errors.companyName ? "Firmanavn skal udfyldes" : ""}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Telefonnummer"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                error={errors.phoneNumber}
                helperText={errors.phoneNumber ? "Telefonnummer skal udfyldes" : ""}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                error={errors.email}
                helperText={errors.email ? "Email skal udfyldes" : ""}
                margin="normal"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={onOk} color="primary" disabled={isSubmitting}>
                <FormattedMessage defaultMessage="Luk" />
              </Button>
              {!formSubmitted ? (
              <Button type="submit" color="primary" autoFocus disabled={isSubmitting}>
                {isSubmitting ? <CircularProgress size={24} /> : <FormattedMessage defaultMessage="Send" />}
              </Button>):null}
            </DialogActions>
          </form>
        )}
      </Dialog>
    );
  }
);
